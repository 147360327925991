@import '../../styles/custom.scss';

.notification-icon-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999; /* Asegura que esté por encima de otros elementos */
  width: 200px; /* Tamaño del contenedor */
  height: 200px; /* Tamaño del contenedor */
  opacity: 0; /* Inicia con opacidad 0 */
  animation: fadeIn 0.5s ease forwards, growIn 0.5s ease forwards; /* Aplica las animaciones de fade in y crecimiento */
  transition: opacity 0.5s ease; /* Agrega una transición suave de opacidad */
}
  
  .notification-icon {
    width: 80%; /* Tamaño de la imagen igual al contenedor */
    height: auto; /* Mantener la proporción de aspecto */
    display: block; /* Para centrar horizontalmente la imagen dentro del contenedor */
    margin: auto; /* Para centrar horizontalmente la imagen dentro del contenedor */
    
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0; /* Inicia con opacidad 0 */
    }
    to {
      opacity: 1; /* Termina con opacidad 1 */
    }
  }
  
  @keyframes growIn {
    from {
      transform: scale(0) translate(-50%, -50%); /* Escala inicial 0 y centrado */
    }
    to {
      transform: scale(1) translate(-50%, -50%); /* Escala final 1 y centrado */
    }
  }

  .notification-icon:hover {
    opacity: 0.8; /* Reduce ligeramente la opacidad al pasar el mouse sobre la imagen */
  }
  
.temas-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Siempre 3 columnas */
  gap: 1rem; /* Espacio entre los elementos de la cuadrícula */
  padding: 1rem;
  justify-items: center;
}

/* Estilos para cada icono de tema */
.icono-tema {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
  border: 2px solid transparent;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
  max-width: 100px;
  width: 100%;
}

.icono-tema:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icono-tema.seleccionado {
  border-color: var(--primary-color); /* Usa la variable de color primaria de tu proyecto */
  background-color: rgba(0, 0, 0, 0.05); /* Color de fondo ligeramente diferente para seleccionados */
}

.avatar_img {
  background-color: $primary-light;
}

.icono-tema__avatar {
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icono-tema__title {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--text-color); /* Usa la variable de color de texto de tu proyecto */
}

/* Media queries para ajustar el diseño en diferentes tamaños de pantalla */
@media (max-width: 768px) {
  
  .icono-tema__title {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .grid-container {
    gap: 1rem;
  }

  .icono-tema {
    width: 100%; /* Ajustar el tamaño para que ocupe toda la fila en pantallas pequeñas */
    max-width: none; /* No establecer un tamaño máximo en pantallas pequeñas */
  }

  .icono-tema__title {
    font-size: 0.9rem;
  }
}


.rating-star-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 1rem 0rem 1rem;
  padding: 1rem 1rem 0rem 1rem;
}


.contenedor-subtemas {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Siempre 3 columnas */
  gap: 1rem; /* Espacio entre los elementos de la cuadrícula */
  padding: 1rem;
  justify-items: center;

  max-height: 18rem; /* Limita la altura del contenedor */
  overflow-y: auto; /* Activa el scroll vertical cuando se exceda la altura */
}