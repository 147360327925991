@import '../../styles/custom.scss';

.floating-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1;
}

.icon-btn-notificacion {
    font-size: 30px; /* Tamaño de icono más grande */
    color: $primary; /* Color del icono */
}

.custom-button {
    width: 60px !important; /* Tamaño del botón */
    height: 60px !important; /* Tamaño del botón */
    background-color: $secondary !important;
    border-radius: 50%;
    display: flex; /* Centrar el icono en el botón */
    align-items: center; /* Centrar verticalmente */
    justify-content: center; /* Centrar horizontalmente */
    transition: all 0.3s ease; /* Transición suave para efectos */
    border: 3px solid $primary;
    cursor: pointer; /* Cambiar el cursor al pasar sobre el botón */
}