.filter-modal {
  border-radius: 12px;
  padding: 16px;
  background-color: #f9f9f9;
}

.filter-chip, .subtema-chip {
  border-radius: 24px;
  padding: 8px;
  font-weight: bold;
}

.subtemas-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.filter-chip {
  transition: background-color 0.2s ease;
}

.filter-chip:hover {
  background-color: #0070f3;
  color: white;
}

.modal-footer {
  justify-content: center;
}
