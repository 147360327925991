.navbar {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
}

.navbar-content {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    padding-left: 0px;
}

.navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-width: 0; /* Permitir que se reduzca */
}

.navbar-logo {
    height: 60px; /* Tamaño fijo del logo */
    min-width: auto;
}

/* Estilos para IconButton y LoginButton */
/* Puedes agregar estilos adicionales según sea necesario */
.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    margin-top: 15px;
    font-size: 20px;
}

.login-button-container {
    position: relative;
}

.login-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    padding: 0;
    font-size: 20px;
}


@media (max-width: 768px) {
    .navbar-logo {
        height: 30px; /* Tamaño fijo del logo en dispositivos móviles */
    }
}