.buttonLogin{
    position: absolute !important; 
    top: 20px !important;
    right: 20px !important;
}
.form-container {
  width: 350px !important;
  height: 500px !important;
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  padding: 20px 30px !important;
}

.modal-title {
  text-align: center !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
  font-size: 28px !important;
  font-weight: 800 !important;
  margin: 0 auto;
}

.btn-close {
  margin: 0 !important;
}

.modal-header {
  padding-right: 32px !important;
  padding-left: 32px !important; 
 }

.modal-body {
  padding: 32px !important;
}

.form {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 18px !important;
  margin-bottom: 15px !important;
}

.input {
  border-radius: 20px !important;
  border: 1px solid #c0c0c0 !important;
  outline: 0 !important;
  box-sizing: border-box !important;
  padding: 12px 15px !important;
}

.page-link {
  text-decoration: underline;
  margin: 0;
  text-align: end;
  color: #747474;
  text-decoration-color: #747474;
}

.page-link-label {
  cursor: pointer;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 9px;
  font-weight: 700;
}

.page-link-label:hover {
  color: #000;
}

.form-btn {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.form-btn:active {
  box-shadow: none;
}

.sign-up-label {
  margin: 0;
  font-size: 10px;
  color: #747474;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.sign-up-link {
  margin-left: 1px;
  font-size: 11px;
  text-decoration: underline;
  text-decoration-color: teal;
  color: teal;
  cursor: pointer;
  font-weight: 800;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 15px;
}

.apple-login-button,
    .google-login-button {
  width: 100%;
      border-radius: 20px !important;
  box-sizing: border-box !important;
  padding: 10px 15px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
  gap: 5px !important;
}


.google-login-button {
  border: 1px solid #747474 !important;
}

.apple-icon,
    .google-icon {
  font-size: 18px !important;
  margin-bottom: 1px !important;
  width: 24px;
  height: 24px;
}

