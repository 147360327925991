.menu-lateral {
    height: 100vh; /* Ocupa el total de la altura */
    display: flex;
    flex-direction: column;
}
  
.menu-lateral .mt-auto {
    margin-top: auto; /* Asegura que esté al final */
}
  
.menu-lateral ul {
    padding-left: 0;
}
  
.menu-lateral a {
    font-size: 0.9rem;
    color: #6c757d;
}
  
.menu-lateral a:hover {
    text-decoration: underline;
}