.info-window-modern {
    position: relative;
    max-width: 270px;
    background-color: white;
    padding-left: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    overflow-x: hidden; 
}

.close-button {
    position: absolute;
    top: 0px;
    right: -15px;
    min-width: auto;
    height: auto;
    background: none;
    border: none;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.close-button:hover {
    opacity: 1;
}

.info-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.bar-name {
    font-size: 18px;
    font-weight: 600;
    white-space: normal; /* Permitir que el texto se divida en varias líneas */
    overflow: hidden; /* Ocultar el contenido desbordado */
    text-overflow: ellipsis; /* Mostrar "..." si el texto es demasiado largo */
    
    /* Soporte de WebKit para limitar a 2 líneas */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    
    /* Fallback básico para navegadores que no soportan -webkit-line-clamp */
    max-height: 2.4em; /* Aproximadamente 2 líneas de texto */
    line-height: 1.2em; /* Asegurar que la altura de línea sea consistente */
}

.bar-details {
    font-size: 14px;
}

.like-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 0px !important;
    margin-right: 20px !important;
    padding-bottom: 0px !important;
    margin-top: 10px !important;
    padding-top: 0px !important;
}

.info-tags {
    margin-top: 10px;
}


