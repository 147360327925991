@import '../../styles/custom.scss';

.search-card-container {
    flex: 1;
    max-width: 180px;
    pointer-events: auto;
    position: absolute;
    left: 20px;
    top: 0px;
}

.search-card {
    max-width: 180px;
    width: 100%;
    height: auto;
}

.search-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 180px;
    width: auto;
    border-bottom: 1px solid #e5e7eb;
    padding: 15px;
    background-color: $primary;
    color: white;
}

.search-card-title {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
}

.search-card-body {
    margin-top: 5px !important;
    margin-bottom: 4px !important;
}

.search-card-footer {
    padding: 10px;
    background-color: white !important;
    border-top: 1px solid #e5e7eb;
}

.search-card-icon {
    width: 28px;
    height: 28px;
}

.search-radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 15px;
}

.map-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff;
}

.location-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    color: $primary;
}

.map-button span {
    border-bottom: 1px solid $primary;
    padding-bottom: 2px;
    color: $primary;
}

.search-radio span{
    font-size: 0.875rem;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

/* Mobile responsiveness */
@media (max-width: 768px) {

    .search-card-container {
        position: relative;
        max-width: 100%;
        left: 0; 
    }

    .search-card {
        width: auto;
        height: auto;
    }

    .search-card-title {
        font-size: 1.25rem;
    }

    .search-radio-group {
        gap: 6px;  
        padding-top: 10px; 
    }

    .search-card-footer {
        padding: 8px;
    }

    .search-card-icon {
        width: 24px;
        height: 24px;
    }

    .map-button span {
        font-size: 0.875rem;
    }
}