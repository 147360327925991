@import '../../styles/custom.scss';

/* Fondo de pantalla con color primario */
.splash-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $primary-dark; /* Color primary (ajústalo a tu esquema de colores) */
  }
  
  /* Contenedor del contenido del splash */
  .splash-content {
    text-align: center;
  }
  
  /* Logo centrado */
  .splash-logo {
    width: 300px;
    margin-bottom: 30px;
  }
  
  /* Barra de carga */
  .loading-bar {
    position: relative;
    width: 300px;
    height: 10px;
    background-color: $secondary; /* Color de fondo de la barra */
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .loading-bar-progress {
    width: 100%;
    height: 100%;
    background-color: $primary-light; /* Color del progreso (blanco en este caso) */
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
  
  /* Animación de búsqueda de bares (lupa) */
  .search-animation {
    margin-top: 80px;
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .search-icon {
    width: 70px;
    height: 70px;
    animation: moveLupa 2s infinite;
  }
  
  @keyframes moveLupa {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(15px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  