@import '../../styles/custom.scss';

.filter-card-container {
    flex: 1;
    max-width: 180px;
    pointer-events: auto;
    position: absolute;
    right: 20px;
}

.filter-card {
    max-width: 180px;
    width: 100%;
    height: auto;
}

.filter-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 180px;
    width: auto;
    border-bottom: 1px solid #e5e7eb;
    padding: 15px;
    background-color: $primary;
    color: white;
}

.filter-card-title {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
}

.filter-card-body {
    margin-bottom: -1em !important;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .filter-card-container {
        position: relative;
        max-width: 100%;
        right: 0; 
        text-align: center;
    }

    .filter-card {
        width: auto;
        height: auto;
    }

    .filter-card-title {
        font-size: 1.25rem;
    }

    .filter-card-body {
        padding: 10px !important;
    }
}
