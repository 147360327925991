/* Map.styles.css */

.icon-navbar {
    position: absolute;
    top: 70px;
    right: 10px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    padding: 5px 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
}
  
.icon-navbar .icon {
    margin: 0 5px;
    cursor: pointer;
    font-size: 20px;
}

.filters-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0 15px;
    position: relative;
}
  
@media (max-width: 768px) {
    
    .search-card.visible, .filter-card.visible {
        display: block;
    }

    .filters-container {
        padding: 0 10px;
    }

}
  