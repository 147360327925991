.star {
    color: grey;
    font-size: 24px;
    margin-right: 5px;
    cursor: pointer;
    transition: color 0.2s;
}

.star:hover {
    color: gold;
    transform: scale(1.2);
}

.star.selected {
    color: gold;
}