.action-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    background-color: transparent;
    position: relative; 
    z-index: 1;
    pointer-events: none; /* Desactivar los eventos de clic en el contenedor */
}

.action-button {
    margin: 0 10px; /* Espaciado entre los botones */
    pointer-events: auto; /* Permitir clics en los botones */
}

/* Responsividad para móviles */
@media (max-width: 768px) {
    .action-buttons-container {
        padding: 10px 0; /* Reducir el padding en dispositivos móviles */
    }

    .action-button {
        font-size: 0.875rem; /* Opcionalmente, hacer los botones más pequeños */
        padding: 10px 15px; /* Ajustar padding interno del botón */
    }
}
