.profile-image {
    width: 100px;
    height: 100px;
}
  
.edit-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background-color: lightgray;
    border-radius: 50%;
    padding: 5px;
    width: 30px; /* Ancho igual a la altura para hacer un círculo */
    height: 30px; /* Altura igual a la anchura para hacer un círculo */
    display: flex;
    justify-content: center; /* Centrar contenido horizontalmente */
    align-items: center; /* Centrar contenido verticalmente */
}
  
.title-text {
    font-size: 1.5rem;
}
  
.email-icon {
    margin-left: 5px;
}

.tags-sections {
  margin: 15px;
}

.preferences-section p {
  margin-bottom: 15px;
}

.field-label {
  font-weight: bold;
  margin-right: 10px;
}

.select-control,
.datepicker-control,
.form-control {
  margin-bottom: 10px;
  width: 100%;
}

.nav-tabs {
  background-color: #f8f9fa; /* Color de fondo del contenedor de Tabs */
  border-bottom: 1px solid #dee2e6; /* Línea divisoria entre Tabs */
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #fff; /* Color de fondo del Tab activo */
  border-bottom-color: transparent; /* Borramos la línea divisoria inferior del Tab activo */
}

.selected-image-name {
    font-family: 'Arial', sans-serif; /* Fuente moderna y limpia */
    font-size: 0.875rem; /* Tamaño de fuente cómodo para la lectura */
    
    margin-top: 0.5rem; /* Espaciado superior para separación del Avatar */
    display: inline-block; /* Mantener el bloque en línea */
    
}
