.searchResultsContainer {
    max-height: 150px; /* Altura máxima del contenedor */
    overflow-y: auto; /* Agrega scroll vertical */
    margin-top: 10px;
    margin-bottom: 10px;
}
  
.listbox {
    width: 100%; /* Ancho del Listbox */
    
}
  
.listItem {
    padding: 10px; /* Espaciado interno del ListboxItem */
    cursor: pointer; /* Cambia el cursor al pasar sobre el ListboxItem */
    border-bottom: 1px solid #eee; /* Líneas entre cada ítem */
    background-color: rgba(0, 0, 0, .03); /* Color de fondo del listado */
}