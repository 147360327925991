// custom.scss

// ------------------------------------------
// Custom Bootstrap Theme
// ------------------------------------------

// ------------------------------------------
// Theme Variables
// ------------------------------------------

$primary: #227c73;
$primary-dark: #17544e;
$primary-light: #2da498;

$secondary: #faf4c1;
$secondary-dark: #f6ec92;
$secondary-amarillo: #f7e75f;
$secondary-light: #fefcf0;

// Light Theme Variables
$theme-colors: (
    "primary": $primary,
    "primary-dark": $primary-dark,
    "primary-light": $primary-light,
    "secondary": $secondary,
    "secondary-dark": $secondary-dark,
    "secondary-amarillo": $secondary-amarillo,
    "secondary-light": $secondary-light
);

$body-bg: #FFFFFF;
$body-color: #000000;

$font-size-base: 1rem;
$line-height-base: 1.5;
$border-radius: 12px;

// Definir sombras personalizadas
$box-shadow-sm: 0px 0px 5px 0px rgb(0 0 0 / 0.02), 0px 2px 10px 0px rgb(0 0 0 / 0.06), 0px 0px 1px 0px rgb(0 0 0 / 0.3);
$box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 0.03), 0px 2px 30px 0px rgb(0 0 0 / 0.08), 0px 0px 1px 0px rgb(0 0 0 / 0.3);
$box-shadow-lg: 0px 0px 30px 0px rgb(0 0 0 / 0.04), 0px 30px 60px 0px rgb(0 0 0 / 0.12), 0px 0px 1px 0px rgb(0 0 0 / 0.3);

// ------------------------------------------
// Bootstrap Import
// ------------------------------------------
@import '~bootstrap/scss/bootstrap';

:root {
    --swiper-navigation-color: #{$primary};
    --swiper-pagination-color: #{$primary};
}
