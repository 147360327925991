@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}
  

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-100 {
    opacity: 1 !important;
}

.data-\[loaded\=true\]\:opacity-100[data-loaded="true"] {
    opacity: 1 !important;
}