@import '../../styles/custom.scss';

.bar-detalles-container {
    padding: 15px;
    border-radius: 8px;
}

.bar-detalles-name {
    font-size: 2rem;
    font-weight: bold;
}

.category-chip {
    margin: 5px;
}

.bar-detalles-info {
    margin-top: 20px;
}

.info-text {
    margin-bottom: 15px;
    color: #666;
    font-size: 1rem;
}

.bar-detalles-title {
    font-size: 2rem;
    font-weight: bold;
    color: $primary-dark; /* Color de texto oscuro */
    margin-top: 1rem;
    transition: color 0.3s; /* Transición suave para hover */
}

.bar-detalles-title:hover {
    color: $primary; /* Color de texto al pasar el ratón */
}



